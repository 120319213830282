import { Transition } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { Locale, makeLocale } from '../helpers'
import { translations } from './types'

export type Menu = 'mobile' | 'profile'

type Props = {
  openMenu: Menu | undefined
}

export const Menu = ({ openMenu }: Props) => {
  const router = useRouter()
  const { pathname } = router
  const locale = makeLocale(router.locale, router.defaultLocale)
  const translation = translations[locale as Locale]

  return (
    <Transition
      show={openMenu === 'mobile'}
      enter="transition ease-out duration-200"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <div className="sm:hidden" id="mobile-menu">
        <div className="pt-2 pb-4 space-y-1">
          <Link
            href="/about"
            className={`${
              pathname.includes('/about')
                ? 'bg-karma-pink-50 border-karma-pink-500 text-karma-pink-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                : 'border-transparent text-karma-black-500 hover:bg-karma-black-50 hover:border-karma-black-300 hover:text-karma-black-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
            }`}>
            {translation.paths.about.label}
          </Link>
        </div>
      </div>
    </Transition>
  )
}
