import { ChevronRightIcon } from '@heroicons/react/solid'
import * as t from 'io-ts'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

import { LocationCenter } from '../../models/types'
import { Locale, makeLocale } from '../helpers'
import useLocationCenterPicker from './useLocationCenterPicker'

type Props = {
  centers: t.TypeOf<typeof LocationCenter>[] | null
  logoImage: string
}

const translations: {
  [key in Locale]: {
    title: string
  }
} = {
  sv: {
    title: 'Välj kök',
  },
  en: {
    title: 'Select kitchen',
  },
  no: {
    title: 'Velg kjøkken',
  },
}

export const SELECTED_CENTER_ID_COOKIE_PATH = 'centerId'

export default function LocationCenterPicker({ centers, logoImage }: Props) {
  const locationCenterPicker = useLocationCenterPicker()
  const router = useRouter()
  const translation = translations[makeLocale(router.locale, router.defaultLocale)]

  return (
    <div className="flex flex-col items-center px-6 pt-4">
      <h1 className="text-2xl font-bold bg pb-4">{translation.title}</h1>
      <div className="w-full max-w-2xl">
        {centers
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((center) => {
            const selectView = async () => {
              const viewId = center.id
              await locationCenterPicker.setCenter(viewId)
            }
            const logoToUse = center.logo || logoImage
            return (
              <button
                key={center.id}
                className="flex flex-col mb-4 w-full items-center shadow-lg rounded overflow-hidden"
                onClick={selectView}>
                {logoToUse && (
                  <Image
                    src={logoToUse}
                    alt="Logo"
                    width={128}
                    height={128}
                    className="h-32 w-full object-cover"
                  />
                )}
                <div className="p-3 flex flex-row items-center w-full">
                  <span className="text-xl text-gray-900 text-left font-bold flex-1 ">
                    {center.name}
                  </span>
                  <ChevronRightIcon className="w-8 h-8 flex-none text-black font-bold text-xl" />
                </div>
              </button>
            )
          })}
      </div>
    </div>
  )
}
