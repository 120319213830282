import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { Locale, makeLocale } from '../helpers'
import { HorizontalKarmaLogo } from '../index'

export const Footer = () => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const translation = translations[locale as Locale]

  return (
    <footer className="container py-12 justify-center sm:flex sm:items-center sm:justify-between">
      <div className="flex justify-center space-x-6 sm:order-2">
        <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
          <Link
            href="/about"
            className={`${
              router.pathname.includes('/about')
                ? 'border-karma-pink-500 text-karma-black-900'
                : 'border-transparent text-karma-black-500 hover:border-karma-black-300 hover:text-karma-black-700'
            } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
            {translation.paths.about.label}
          </Link>
        </div>
      </div>
      <div className="mt-8 sm:mt-0 sm:order-1 flex gap-x-2 items-center justify-center">
        <p>{translation.poweredBy}</p>
        <HorizontalKarmaLogo className="h-8 w-auto" color="#000" />
      </div>
    </footer>
  )
}

type Path = {
  label: string
}
export const translations: {
  [key in Locale]: {
    screenReaderOpenMainMenu: string
    screenReaderOpenCart: string
    poweredBy: string
    paths: {
      about: Path
    }
  }
} = {
  sv: {
    screenReaderOpenMainMenu: 'Öppna menyn',
    screenReaderOpenCart: 'Öppna varukorg',
    poweredBy: 'Drivs av',
    paths: {
      about: {
        label: 'Om oss',
      },
    },
  },
  en: {
    screenReaderOpenMainMenu: 'Open main menu',
    screenReaderOpenCart: 'Open cart',
    poweredBy: 'Powered by',
    paths: {
      about: {
        label: 'About',
      },
    },
  },
  no: {
    screenReaderOpenMainMenu: 'Åpne menyen',
    screenReaderOpenCart: 'Åpen handlekurv',
    poweredBy: 'Drevet av',
    paths: {
      about: {
        label: 'Om oss',
      },
    },
  },
}
