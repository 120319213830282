import NextHead from 'next/head'

type Props = {
  title?: string
  description?: string
  url?: string
  ogImage?: string
  qrCodeId?: string
}

export const Head = (props: Props) => {
  const title = props.title ? props.title : 'Karma Storefront'
  const description = props.description ? props.description : 'Karma Storefront'

  // See https://github.com/karmadev/storefront-web/pull/511
  const canonicalUrl = props.qrCodeId ? `/code/${props.qrCodeId}` : '/takeaway'

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="twitter:creator" content="@YourKarmaApp" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="og:image" content="/og-image-default.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
      <meta name="twitter:card" content="summary_large_image" />
    </NextHead>
  )
}
