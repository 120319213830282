import { useRouter } from 'next/router'
import React from 'react'

import { LocationTheme } from '../../models/types'
import { Locale, makeLocale } from '../helpers'
import { Menu } from './menu'
import { translations } from './types'

type Props = {
  selectedTheme: LocationTheme | null
  openMenu: Menu | undefined
  setOpenMenu: React.Dispatch<React.SetStateAction<Menu | undefined>>
}

export const MenuButton = ({ selectedTheme, openMenu, setOpenMenu }: Props) => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const translation = translations[locale as Locale]

  return (
    <div className="flex items-center sm:hidden">
      <button
        type="button"
        className={
          selectedTheme
            ? `inline-flex items-center justify-center p-2 text-[color:var(--brand-text-on-primary-color)]`
            : `inline-flex items-center justify-center p-2 text-karma-black-900`
        }
        aria-controls="mobile-menu"
        aria-expanded="false"
        onClick={() => setOpenMenu(openMenu === 'mobile' ? undefined : 'mobile')}>
        <span className="sr-only">{translation.screenReaderOpenMainMenu}</span>
        <svg
          className={`h-6 w-6 ${openMenu === 'mobile' ? 'hidden' : 'block'}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>

        <svg
          className={`h-6 w-6 ${openMenu === 'mobile' ? 'block' : 'hidden'}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  )
}
