import { MenuIcon, SearchIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

import { useSearch } from '../../hooks/useSearchContext'
import { useUserProfileSheet } from '../../hooks/useUserProfile/context'
import { LocationInformation } from '../../models/types'
import { Heading } from '../../ui/Typography'
import { getSelectedTheme } from '../../utils/location-center-helpers'
import { BackButton } from './back-button'
import { Menu } from './menu'
import { MenuButton } from './menu-button'

export enum NavbarButton {
  Back = 'back',
  Menu = 'menu',
  Search = 'search',
}

export type NavbarOptions = {
  leftButton?: NavbarButton.Back | NavbarButton.Menu | null
  rightButton?: NavbarButton.Search
  title?: string | null
  goBackToHref?: string
  transparentBackground?: boolean
  buttonAction?: () => void
  shouldUseRouterBack?: boolean
  invertColor?: boolean
  extraSidePadding?: boolean
}

type Props = {
  locationInfo: Pick<LocationInformation, 'themes' | 'locationCenters'>
  options?: NavbarOptions
}

export const Navbar = ({ locationInfo, options }: Props) => {
  const {
    leftButton = NavbarButton.Back,
    rightButton,
    title,
    goBackToHref,
    invertColor = false,
    extraSidePadding = false,
  } = options || {}
  const { toggleSearch } = useSearch()
  const selectedTheme = getSelectedTheme(locationInfo)
  const [openMenu, setOpenMenu] = useState<Menu>()
  const userProfileSheet = useUserProfileSheet()

  // NOTE(christoffer) Seems like we have some inconsistencies in side padding on pages.
  // At the time of writing I've only seen two different padding:s, so calling this "extra
  // padding" as a one-off case, rather than trying to generalize it.
  const sidePaddingSizeValueL = extraSidePadding ? 'ml-[2.0rem]' : 'ml-4'
  const sidePaddingSizeValueR = extraSidePadding ? 'mr-[2.0rem]' : 'mr-4'

  const renderLeftButton = () => {
    switch (leftButton) {
      case NavbarButton.Menu:
        return (
          <MenuButton selectedTheme={selectedTheme} openMenu={openMenu} setOpenMenu={setOpenMenu} />
        )
      case NavbarButton.Back:
        return (
          <BackButton
            goBackToHref={goBackToHref}
            buttonAction={options?.buttonAction}
            invertColor={invertColor}
            shouldUseRouterBack={options?.shouldUseRouterBack}
          />
        )
      default:
        return null
    }
  }

  const HamburgerButton = (props: { transparentBackground: boolean }) => {
    const styleClass = props.transparentBackground ? `bg-white` : `bg-transparent`
    const textColor = props.transparentBackground
      ? 'text-default'
      : 'text-[color:var(--brand-text-on-primary-color)]'
    return (
      <button
        className={`${textColor} inline-flex items-center justify-center p-1 rounded-full ${styleClass}`}
        onClick={() => {
          userProfileSheet.setIsSheetOpen(true)
        }}>
        <MenuIcon className="h-6 w-6" />
      </button>
    )
  }

  return (
    <nav
      className={`text-[color:var(--brand-text-on-primary-color)] ${
        options?.transparentBackground
          ? 'bg-transparent -mb-20'
          : 'bg-[color:var(--brand-primary-color)]'
      }`}>
      <div className="sm:container relative flex justify-between h-14">
        <div className="w-full flex flex-row justify-between items-center">
          <div className={`flex ${sidePaddingSizeValueL} justify-center`}>{renderLeftButton()}</div>

          {title !== undefined && (
            <div className="px-2">
              <Heading
                color="brand"
                level={5}
                className="text-center overflow-hidden break-words line-clamp-1">
                {title}
              </Heading>
            </div>
          )}

          <div className={`flex basis-8 justify-center ${sidePaddingSizeValueR}`}>
            {rightButton === NavbarButton.Search ? (
              <div className="flex items-center justify-center w-8 h-8">
                <button
                  onClick={toggleSearch}
                  className={
                    selectedTheme
                      ? 'w-5 h-5 text-base font-medium text-[color:var(--brand-text-on-primary-color)]'
                      : 'w-5 h-5 text-base font-medium text-karma-black-900 sm:text-karma-black-500 hover:text-karma-black-900'
                  }>
                  <SearchIcon />
                </button>
              </div>
            ) : null}
            {userProfileSheet.isEnabled ? (
              <div className="w-8 h-8">
                <HamburgerButton transparentBackground={options?.transparentBackground ?? false} />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {leftButton === NavbarButton.Menu && <Menu openMenu={openMenu} />}
    </nav>
  )
}
