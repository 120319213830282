import { Locale } from '../helpers'

type Path = {
  label: string
}
export const translations: {
  [key in Locale]: {
    screenReaderOpenMainMenu: string
    screenReaderOpenCart: string
    tableOrderPrompt: string
    paths: {
      about: Path
    }
  }
} = {
  sv: {
    screenReaderOpenMainMenu: 'Öppna menyn',
    screenReaderOpenCart: 'Öppna varukorg',
    tableOrderPrompt: 'Fortfarande vid',
    paths: {
      about: {
        label: 'Om oss',
      },
    },
  },
  en: {
    screenReaderOpenMainMenu: 'Open main menu',
    screenReaderOpenCart: 'Open cart',
    tableOrderPrompt: 'Still at',
    paths: {
      about: {
        label: 'About',
      },
    },
  },
  no: {
    screenReaderOpenMainMenu: 'Åpne menyen',
    screenReaderOpenCart: 'Åpen handlekurv',
    tableOrderPrompt: 'Fortsatt på',
    paths: {
      about: {
        label: 'Om oss',
      },
    },
  },
}
