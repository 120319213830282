import { useRouter } from 'next/router'
import nookies from 'nookies'

import { useSession } from '../../hooks/useSession'
import { getSelectedCenterId } from '../../utils/location-center-helpers'
import { SELECTED_CENTER_ID_COOKIE_PATH } from './location-center-picker'

const useLocationCenterPicker = () => {
  const router = useRouter()

  const { session } = useSession()
  const qrCodeCenterId = session?.qrCodeParams?.center as string
  const selectedCenterId = getSelectedCenterId(qrCodeCenterId)
  const hasSelectedCenter = !!selectedCenterId
  // If the location center is on a QR code basis the user can't change it.
  const canPickCenter = !qrCodeCenterId && hasSelectedCenter

  const setCenter = async (viewId: string | number) => {
    nookies.set(null, SELECTED_CENTER_ID_COOKIE_PATH, `${viewId}`, {
      path: '/',
      maxAge: 60 * 60, // 1 hour
      secure: true,
      sameSite: 'lax',
    })
    await router.reload()
  }
  const clearCenter = async () => {
    nookies.destroy(null, SELECTED_CENTER_ID_COOKIE_PATH, { path: '/' })
    await router.reload()
  }

  return {
    setCenter,
    clearCenter,
    hasSelectedCenter,
    canPickCenter,
  }
}

export default useLocationCenterPicker
