import { ArrowLeftIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'
import React from 'react'

type Props = {
  goBackToHref?: string
  buttonAction?: () => void
  invertColor: boolean
  shouldUseRouterBack?: boolean
}

export const BackButton = ({
  goBackToHref,
  buttonAction,
  invertColor,
  shouldUseRouterBack,
}: Props) => {
  const router = useRouter()

  const handleRouteBack = () => {
    if (buttonAction) {
      buttonAction()
    }

    if (shouldUseRouterBack) {
      router.back()
      return
    }
    if (goBackToHref) {
      router.prefetch(goBackToHref)
      router.push(goBackToHref)
      return
    }
  }

  return (
    <button
      className={`text-default  ${
        invertColor ? 'text-white' : 'text-[color:var(--brand-text-on-primary-color)]'
      } inline-flex items-center justify-center p-1 rounded-full`}
      onClick={handleRouteBack}>
      <ArrowLeftIcon className="h-6 w-6" />
    </button>
  )
}
