import { GetServerSidePropsContext } from 'next'
import nookies from 'nookies'

import { Session } from '../api-utils/Session'
import { SELECTED_CENTER_ID_COOKIE_PATH } from '../components/location-center-picker/location-center-picker'
import { LocationCenter, LocationTheme } from '../models/types'

export const getSelectedCenterId = (
  // This QR code is only accessible in the session, so we have to pass it down
  qrCodeCenterId: number | string | undefined,
  // Next session is need for server side cookies
  nextContext?: GetServerSidePropsContext
): number | null => {
  const selectedCenterCookie = nookies.get(nextContext, { path: '/' })[
    SELECTED_CENTER_ID_COOKIE_PATH
  ]
  if (selectedCenterCookie) {
    return Number(selectedCenterCookie)
  }
  if (qrCodeCenterId) {
    return Number(qrCodeCenterId)
  }
  return null
}

export const getSelectedLocationCenter = (
  availableCenters?: LocationCenter[] | null,
  // This center id can be set on a QR code basis and the user can't change it
  qrCodeParams?: Session['qrCodeParams'],
  nextContext?: GetServerSidePropsContext
): LocationCenter | null => {
  if (!availableCenters || !availableCenters?.length) {
    return null
  }
  const qrCodeCenterId = qrCodeParams?.center as string
  const selectedCenterId = getSelectedCenterId(qrCodeCenterId, nextContext)
  const selectedCenter = availableCenters.find((view) => view.id === selectedCenterId) ?? null
  return selectedCenter
}

const DEFAULT_THEME: LocationTheme = {
  id: -1,
  brandPrimaryColor: '#fff',
  brandColorTextOnPrimary: '#000',
}

export function getSelectedTheme(
  information: {
    themes?: LocationTheme[] | null
    locationCenters?: LocationCenter[] | null
  },
  qrCodeParams?: Session['qrCodeParams']
): LocationTheme | null {
  const { themes } = information
  if (!themes) {
    return null
  }
  // A location center might select a theme
  const selectedCenter = getSelectedLocationCenter(information.locationCenters, qrCodeParams)
  if (selectedCenter) {
    const locationCentersTheme = themes.find((theme) => theme.id === selectedCenter.themeId)
    return {
      ...DEFAULT_THEME,
      ...locationCentersTheme,
    }
  }
  if (themes?.length === 1) {
    // When there is only one theme configured for a location lets use it. This could be
    // extended to have multiple themes for a location and perhaps a theme picker
    return themes[0]
  }
  return null
}
